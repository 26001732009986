import {
    handleQueryResolve
} from '../utils';

export default function (patientID, rxNumber, genericName, brandName) {

    if (patientID) {

        let attrs = [{
            param: 'PatientID',
            type: 'sql.Int',
            value: patientID // to support like
        }];
        let where = ['PatientID = @PatientID'];

        if (rxNumber) {
            attrs.push({
                param: 'rxNumber',
                type: 'sql.VarChar(50)',
                value: '%' + rxNumber + '%' // to support like
            });

            where.push('RXNumber LIKE @rxNumber');
        }

        if (genericName) {
            attrs.push({
                param: 'genericName',
                type: 'sql.VarChar(50)',
                value: '%' + genericName + '%' // to support like
            });

            where.push('GenericName LIKE @genericName');
        }

        if (brandName) {
            attrs.push({
                param: 'brandName',
                type: 'sql.VarChar(50)',
                value: '%' + brandName + '%' // to support like
            });

            where.push('BrandName LIKE @brandName');
        }

        return this.query(`
        SELECT "DBA"."Prescription"."RxNumber" AS "RxNumber",
        "DBA"."Prescription"."FillDate" AS "RxDate",
        "DBA"."Prescription"."InstructionText" AS "SIGFull",
        "DBA"."Prescription"."FillQuantity" AS "RxQtyDispense",
        "DBA"."Drug"."GenericName" AS "GenericName",
        "DBA"."Drug"."DIN" AS "DIN",
        "DBA"."Drug"."TradeName" AS "BrandName",
        "DBA"."Drug"."Strength" AS "RxStrength",
        "DBA"."Prescription"."PatientId" AS "PatientID",
        "DBA"."Doctor"."LastName",
        "DBA"."Prescription"."DaysSupply" as "Days",
        "DBA"."Doctor"."FirstName",
        "DBA"."Doctor"."PrescriberId" AS "CPSO",
        "DBA"."Address"."Line1",
        "DBA"."Address"."Phone" AS DoctorPhone,
        "DBA"."Address"."Fax" AS DoctorFax,
        "DBA"."Address"."CityName",
        "DBA"."Address"."ProvinceName",
        "DBA"."Address"."PostalCode",
        "DBA"."DoctorPractice"."Description",
        "DBA"."Prescription"."DoctorId"
        FROM ( ( ( ( ( ( "DBA"."Prescription" INNER JOIN "DBA"."PrescriptionDosset" ON "DBA"."DBA"."Prescription".Id = "DBA"."PrescriptionDosset"."RxId" ) INNER JOIN "DBA"."Drug" ON "DBA"."Prescription".DrugId = "DBA"."Drug".Id ) JOIN "DBA"."DrugForm" ON "DBA"."Drug"."FormCode" = "DBA"."DrugForm"."Code" ) LEFT OUTER JOIN "DBA"."Doctor" ON "DBA"."Prescription"."DoctorId" = "DBA"."Doctor"."Id" ) CROSS JOIN "DBA"."Address" ) JOIN "DBA"."AddressRole" ON "DBA"."Address"."ID" = "DBA"."AddressRole"."AddressId" ) JOIN "DBA"."DoctorPractice" ON "DBA"."Doctor"."PracticeCode" = "DBA"."DoctorPractice"."Code"
        WHERE "DBA"."AddressRole"."EntityId" = "DBA"."Doctor"."Id" AND "DBA"."AddressRole"."AddressId" = "DBA"."Address"."Id" AND"DBA"."AddressRole"."EntityCode" = 'DOC' AND FillDate > DATEADD( year,  -1, GetDate() ) AND ${ where.join(' AND ')}
        ORDER BY "RxDate" DESC   
        `, attrs).then(value => {
            let values = value.map(data => {
                let modifiedData = {...data}
                modifiedData['Days'] = Math.trunc(parseInt(data.Days))
                return modifiedData
            })
            return values
        })
            .then(handleQueryResolve);
    }

}